//header {
//  padding-bottom: 1%;
//}

#tchpc-navbar a {
  border-bottom: none;
  float: left;
  text-decoration: none;
  font-size: 1.122em;
  line-height: 1.35;
  font-weight: 400;
  font-family: 'Open Sans';
  padding: 0px 10px 0px 10px;
  margin: 0;
}

#tchpc-navbar .nav-item.nav-link:hover {
  background-color: #01a2c6;
  -webkit-transition: background-color 1ms linear;
  -moz-transition: background-color 0.1s linear;
  -ms-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}

#tchpc-navbar .nav-item.nav-link.active {
  background-color: #01a2c6;
  -webkit-transition: background-color 1ms linear;
  -moz-transition: background-color 0.1s linear;
  -ms-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}

#tchpc-navbar.navbar {
  padding: 0rem 1rem;
}

#tchpc-navbar .navbar-toggler {
  margin-bottom: 10px;
}
